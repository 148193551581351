:root {
  font-family: Satoshi Variable;
}

/* Top frame of page 1 */

.parent-frame {
  position: relative;
  z-index: 1;
  height: 214px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0px 0px 14px 0px rgba(198, 191, 191, 0.25);
}

.navbar {
  width: 890px;
  height: 80px;
  border-radius: 122px;
  border: 1px solid #FCFCFC;
  position: relative;
  top: -35px;
  left: 300px;
  z-index: 2;
  box-shadow: 0px 0px 20px 0px #7F7F7F26;
}

.navbar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: inherit;
  width: 838px;
  height: 48px;
  top: 16px;
  left: 26px;
  font-weight: 500;
}


.element {
  border-radius: 12px;
  transition: width 1s ease, transform 1s ease;

}

.element :hover {
  width: 100%;
  transform: translateY(+1.25px);
  box-shadow: 0px 0px 20px 0px #7F7F7F26;
}

.navbar-content a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  cursor: context-menu;
  text-decoration: none;
  color: black;
  margin: 0 15px;
  gap: 11.14px;
  border-radius: 12px;
  position: relative;
}

.navbar-logo {
  width: 44px;
  height: 44.68px;
  top: 1.66px;
}

.navbar-links {
  width: 613px;
  height: 48px;
  gap: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.create-jobs-btn {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  width: 123px;
  height: 38px;
  gap: 10px;
  border-radius: 30px;
  font-size: 16px;
  background: linear-gradient(180deg, #A128FF 0%, #6100AD 113.79%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  transition: transform 0.17s ease-in-out;
}

.create-jobs-btn:hover {
  transform: scale(1.1);
}

.create-jobs-btn::before {
  content: 'Login';
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #A128FF 0%, #6100AD 113.79%);
  color: white;
  transition: bottom 0.17s ease-in-out;
  z-index: 1;
}

.create-jobs-btn:hover::before {
  bottom: 0;
}

.create-jobs-btn span {
  transition: transform 0.17s ease-in-out;
}

.create-jobs-btn:hover span {
  transform: translateY(-100%);
}

/* Filters section */
.filters {
  width: 100vw;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 5px;
}

.filter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24%;
}
#borders1 {
  border-left: 2px solid #EAEAEA;
  border-right: 2px solid #EAEAEA;
}
#borders2 {
  border-right: 2px solid #EAEAEA;
}

.filter input {
  width: 75%;
  border: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: left;
  color: #686868;
  margin-left: 25px;
}

.filter input:focus {
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: left;
  color: #686868;
  margin-left: 25px;
}

.filter input::placeholder {
  font-size: 16px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: left;
  color: #686868;
}

.filter select {
  width: 65%;
  border: none;
  outline: none;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  color: #686868;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: left;
  margin-left: 25px;
}
.filter select:focus {
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: left;
}
.filter select option {
  color: #686868;
  /* Apply color to all options */
}
.filter select:invalid {
  color: #686868;
}

#range {
  display: flex;
  justify-content: center;
  align-items: first baseline;
  flex-direction: column;
}

#title {
  width: 70%;
  display: flex;
  justify-content: space-around;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  color: #222222;
}

#range input[type="range"] {
  -webkit-appearance: none;
  width: 70%;
  height: 2px;
  background: black;
  outline: none;
  margin-top: 25px;
}

#range input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
  background: black;
  border-radius: 50%;
  cursor: pointer;
}

#range input[type="range"]::-moz-range-thumb {
  width: 10px;
  height: 10px;
  background: black;
  border-radius: 50%;
  cursor: pointer;
}


/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */
/* job creation modal, Page 2 */

.modal-overlay {
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  box-shadow: 0px 0px 24px 0px #A9A9A940;
  background: #fff;
  width: 848px;
  top: 117px;
  gap: 0px;
  opacity: 1;
  border-radius: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: fit-content;
  font-family: 'Satoshi', sans-serif;
  overflow: auto;
}



.modal-content h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32.4px;
  text-align: left;
  color: #222222;
  margin-bottom: 40px;
}

form {
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 15px;
}

.row .entry {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 376px;
  height: 91px;
  gap: 6px;
}

label {
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
}

input {
  display: flex;
  height: 58px;
  gap: 0px;
  border-radius: 10px;
  border: 1px solid #BCBCBC;
  box-sizing: border-box;
  align-items: center;
  padding-left: 7px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24.3px;
  text-align: left;
  color: #222222;

}

input::placeholder {
  font-size: 16px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: left;
  color: #BCBCBC;
  align-items: center;
}

select {
  display: flex;
  height: 58px;
  gap: 0px;
  border-radius: 10px;
  border: 1px solid #BCBCBC;
  box-sizing: border-box;
  align-items: center;
  padding-left: 7px;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: left;
  color: #222222;
  align-items: center;
}
select option:not([value=""]) {
  color: #222222;
}
select:invalid {
  color: #BCBCBC;
}


.salary-range {
  width: 376px;
  display: flex;
  justify-content: space-between;
}

.salary-range input {
  width: 175px;
  gap: 0px;
  border-radius: 10px;
}

textarea {
  padding: 7px;
  width: 795px;
  height: 169px;
  top: 475px;
  left: 40px;
  gap: 0px;
  border-radius: 10px;
  border: 1px solid #BCBCBC;

  font-size: 18px;
  font-weight: 500;
  line-height: 24.3px;
  text-align: left;
  color: #222222;
}

textarea::placeholder {
  font-size: 16px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: left;
  color: #BCBCBC;
}

input:focus,
select:focus,
textarea:focus {
  border: 1px solid #222222;
  font-size: 18px;
  font-weight: 500;
  line-height: 24.3px;
  text-align: left;
}

.row-description {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 24px 0 24px;
  margin-bottom: 15px;
}

.row-buttons {
  width: 848px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.row-buttons .save-draft {
  width: 232px;
  height: 59px;
  gap: 10px;
  border-radius: 10px;
  border: 1.5px;
  margin-left: 23px;
  margin-right: 23px;
  border: 1.5px solid #222222;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 4px 0px #00000040;
  font-family: 'Satoshi', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
}

.row-buttons .publish {
  color: white;
  background-color: #00AAFF;
  width: 232px;
  height: 59px;
  gap: 10px;
  border-radius: 10px;
  border: 1.5px;
  margin-left: 23px;
  margin-right: 23px;
  box-shadow: 0px 0px 4px 0px #00000040;
  font-family: 'Satoshi', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
}

.angles {
  font-size: 15px;
  gap: 0px;
  border: 2px;
}

.sideways {
  font-size: 15px;
  gap: 0px;
  border: 2px;
}

.updown {
  display: flex;
  align-items: center;
  background-image: url('./assets/updown.svg');
  background-repeat: no-repeat;
  background-position: 140px center;
  font-size: 15px;
  border: 1.5px solid #BCBCBC;
  margin-top: 7px;
  margin-right: 3px;
}

.entry input[type="date"] {
  position: relative;
  padding-right: 40px;
  font-size: 18px;
  text-align: left;
}

.entry input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */
/* Job Listing frame */

.second-frame {
  background-color: #f6f6f66b;
  min-height: 100vh;
}

.job-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(316px, 1fr));
  gap: 16px;
  margin: 25px 40px;
  height: auto;
}

.job-card {
  position: relative;
  width: 316px;
  height: 360px;
  border-radius: 12px;
  box-shadow: 0px 0px 30px 0px #D3D3D326;
  background-color: #FFFFFF;
  padding: 12px;
}



.header {
  display: flex;
  justify-content: space-between;
}

.icon-div {
  padding: 2px;
  background: linear-gradient(180deg, #FEFEFD 0%, #F1F1F1 100%);
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 10.25px 0px #94949440;

  width: 83.46px;
  height: 82px;
  border-radius: 13.18px;
  border: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-div img {
  width: 65.89px;
  height: 65.89px;
}

.time {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 33px;
  border-radius: 10px;
  background-color: #B0D9FF;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.9px;
  text-align: center;
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info span {
  font-size: 16px;
  font-weight: 490;
  line-height: 21.6px;
  text-align: center;
}

.description {
  width: 300px;
  max-height: 76px;
  overflow-y: auto;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  color: #555555;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.9px;
  text-align: left;

}

.description span {
  padding-left: 12.5px;
  position: relative;
}

.description span::before {
  content: '•';
  position: absolute;
  left: 0;
  color: #000;
}

.apply-btn {
  display: flex;
  justify-content: center;
  margin: 0 5px;
}

.apply-now {
  position: absolute;
  bottom: 15px;
  width: 300px;
  height: 46px;
  padding: 12px 10px 12px 10px;
  gap: 10px;
  border-radius: 10px;
  border: 1px;
  background-color: #00AAFF;
  border: 1px solid #00AAFF;
  box-shadow: 0px 0px 14px 0px #5D5D5D26;
  color: white;
}